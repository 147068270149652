import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import AppAppBar from "../components/AppAppBar";
import getLPTheme from "../theme/getLPTheme";
import ChatScreen from "../pages/ChatScreen";
import Hero from "../components/Hero";
export default function LandingPage() {
  const [mode, setMode] = React.useState("dark");
  const [showCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Router>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/chat" element={<ChatScreen />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
