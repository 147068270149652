import React, { useEffect, useRef, useState, useCallback } from "react";
import { alpha, Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Peer from "peerjs";
import axiosInstance from "../axiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
export default function Hero() {
  const navigate = useNavigate();
  const [peerId, setPeerId] = useState("");
  const [call, setCall] = useState(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerInstance = useRef(null);
  const [localStream, setLocalStream] = useState(null);
  const [fakeVideoUrl, setFakeVideoUrl] = useState(null);
  const [isFinding, setIsFinding] = useState(true);

  const getRandomUser = async (payload) => {
    console.log("payload is", payload);
    try {
      const response = await axiosInstance.post(
        "/connect",
        JSON.stringify(payload)
      );
      const targetPeerId = response.data?.targetPeerId;
      if (targetPeerId) {
        // if (targetPeerId === peerId) {
        //   getRandomUser();
        //   return;
        // }
        console.log("target user id is", targetPeerId);
        callPeer(targetPeerId);
        setIsFinding(false);
      } else {
        console.log("no users available to join, handle fake video stream");
        const fakeVideo = response.data?.fakeUserStream;
        if (fakeVideo) {
          setFakeVideoUrl(fakeVideo);
          setIsFinding(false);
        } else {
          console.log("no users and fake urls found");
        }
      }
      // callPeer(targetPeerId);
    } catch (error) {
      console.log("something is wrong", error);
    }
  };

  const callPeer = useCallback(
    (remotePeerId) => {
      if (!remotePeerId) {
        console.log("no remote peer");
        return;
      }
      const outgoingCall = peerInstance.current.call(remotePeerId, localStream);
      outgoingCall.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
      });
      setCall(outgoingCall);
    },
    [localStream]
  );

  useEffect(() => {
    const peer = new Peer();
    peer.on("open", (id) => {
      if (id) {
        setPeerId(id);
        console.log("peerId", id);
        let data = {
          peerId: id,
        };
        getRandomUser(data);
      }
    });

    peer.on("call", (incomingCall) => {
      incomingCall.answer(localStream);
      incomingCall.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
      });
      setCall(incomingCall);
      setFakeVideoUrl(null);
    });
    peerInstance.current = peer;
    return async () => {
      peer.destroy();
      if (peerId) {
        await axiosInstance.post(
          "disconnect",
          JSON.stringify({ peerId: peerId })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callPeer, localStream]);

  useEffect(() => {
    async function getCameraStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setLocalStream(stream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    }
    getCameraStream();
  }, []);

  const endCall = () => {
    navigate("/");

    // if (call) {
    //   call.close();
    //   setCall(null);
    //   setFakeVideoUrl(null);
    // }
    // if (!peerId) {
    //   console.log("peerID is not found");
    // } else {
    //   // getRandomUser({ peerId: peerId });
    // }
  };

  const handleNext = () => {
    if (call) {
      call.close();
      setCall(null);
      setFakeVideoUrl(null);
    }
    if (!peerId) {
      console.log("peerID is not found");
    } else {
      getRandomUser({ peerId: peerId });
    }
  };

  const renderVideo = () => {
    if (isFinding) {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      );
    }
    if (fakeVideoUrl) {
      return (
        <video
          loop
          src={fakeVideoUrl}
          muted
          playsInline
          autoPlay
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    } else
      return (
        <video
          loop
          ref={remoteVideoRef}
          muted
          playsInline
          autoPlay
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
  };

  console.log("peer id is", peerId);
  return (
    <Box sx={{ pt: 10, height: "100vh" }}>
      <Grid
        container
        id="hero"
        sx={(theme) => ({
          width: "100%",
          height: "100%",
          backgroundImage:
            theme.palette.mode === "light"
              ? "linear-gradient(180deg, #CEE5FD, #FFF)"
              : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Grid container direction="column" sx={{ flexGrow: 1 }}>
            <Grid item sx={{ height: "38vh", overflow: "hidden" }}>
              <Box
                sx={{
                  // backgroundColor: "white",
                  height: "100%",
                }}
              >
                {renderVideo()}
              </Box>
            </Grid>
            <Grid item sx={{ height: "38vh", overflow: "hidden" }}>
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                }}
              >
                <video
                  ref={localVideoRef}
                  playsInline
                  autoPlay
                  muted
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <Button
                    onClick={endCall}
                    sx={{ width: "40%", opacity: 0.7 }}
                    variant="contained"
                    color="primary"
                  >
                    <Link>
                      <Typography variant="h5">Back</Typography>
                    </Link>
                  </Button>
                  <Button
                    onClick={handleNext}
                    sx={{ width: "40%", opacity: 0.7 }}
                    variant="contained"
                    color="primary"
                  >
                    <Link>
                      <Typography variant="h5">Find Next</Typography>
                    </Link>
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* <Grid
              container
              item
              sx={{
                height: "13vh",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Button
                onClick={endCall}
                style={{ width: "200px" }}
                variant="contained"
                color="primary"
              >
                <Typography variant="h5">Next</Typography>
              </Button>
            </Grid> */}
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={7}>
          <Box
            id="image"
            sx={(theme) => ({
              height: "100%",
              width: "100%",
              backgroundImage:
                theme.palette.mode === "light"
                  ? 'url("/static/images/templates/templates-images/cover.jpg")'
                  : 'url("/static/images/templates/templates-images/cover.jpg")',
              backgroundSize: "cover",
              borderRadius: "10px",
              outline: "1px solid",
              outlineColor:
                theme.palette.mode === "light"
                  ? alpha("#BFCCD9", 0.5)
                  : alpha("#9CCCFC", 0.1),
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                  : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
            })}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
